import { urlsWrapper } from 'config';
import { createSelector } from 'reselect';
import {
  saveData,
  createCommonListConstants,
  createCommonListReducer,
} from './utils';
import { commonGet } from './api';

const constants = createCommonListConstants('ADVISOR_USERS');

// reducer
export default createCommonListReducer(constants);

// actions
export function getAdvisorUsersList() {
  return saveData(
    urlsWrapper('advisorUsers'),
    constants.SET_LIST,
    constants.SET_IS_PENDING
  );
}

export const getBayerTsCustomers = () =>
  commonGet({
    url: `${urlsWrapper('bayerTsCustomers')}`,
  });

export const getBayerTsCustomer = (id) =>
  commonGet({
    url: `${urlsWrapper('bayerTsCustomers')}/${id}`,
  });

export const getAvailableAdvisorUsers = () =>
  commonGet({
    url: `${urlsWrapper('availableadvisorusers')}`,
  });

// selector
export const getAdvisorUsersSelector = createSelector(
  [(state) => state.advisorUsers],
  (users) => users
);
