import { combineReducers } from 'redux';
import { urlsWrapper } from 'config';
import { createSelector } from 'reselect';
import {
  saveData,
  createCommonListConstants,
  createCommonListReducer,
  createCommonItemConstants,
  createCommonItemReducer,
} from './utils';
import { commonDelete, commonPost, commonPut, commonGet } from './api';

const seasonPlans = createCommonListConstants('SEASON_PLANS');
const seasonPlansFields = createCommonListConstants('SEASON_PLANS_FIELDS');
const seasonPlansWorkrecord = createCommonItemConstants(
  'SEASON_PLANS_WORK_RECORD'
);
const seasonPlansWorkrecords = createCommonItemConstants(
  'SEASON_PLANS_WORK_RECORDS'
);

export const getSeasonPlans = () =>
  saveData(
    `${urlsWrapper('seasonplans')}`,
    seasonPlans.SET_LIST,
    seasonPlans.SET_IS_PENDING
  );

const shouldFetchSeasonPlans = () => true; // todo

export const fetchSeasonPlansIfNeeded = () => (dispatch, getState) =>
  shouldFetchSeasonPlans(getState())
    ? dispatch(getSeasonPlans())
    : Promise.resolve();

export const getSeasonPlan = (id) =>
  commonGet({
    url: `${urlsWrapper('seasonplans')}/${id}`,
    name: 'технологическую карту',
  });

export const addSeasonPlan = (data) => () =>
  commonPost({
    url: `${urlsWrapper('seasonplans')}`,
    data,
    name: 'технологическую карту',
  });

export const copySeasonPlan = (seasonPlanId, data) => () =>
  commonPost({
    url: `${urlsWrapper('seasonplans')}/${seasonPlanId}/copy`,
    data,
    name: 'технологическую карту',
  });

export const editSeasonPlan = (seasonPlanId, data) => () =>
  commonPut({
    url: `${urlsWrapper('seasonplans')}/${seasonPlanId}`,
    data,
    name: 'технологическую карту',
  });

export const deleteSeasonPlan = (seasonPlanId) => () =>
  commonDelete({
    url: `${urlsWrapper('seasonplans')}/${seasonPlanId}`,
  });

export const getSeasonPlansFields = (seasonPlanId) =>
  saveData(
    `${urlsWrapper('seasonplans')}/${seasonPlanId}/fields`,
    seasonPlansFields.SET_LIST,
    seasonPlansFields.SET_IS_PENDING
  );

export const addSeasonPlansFieldsField = (seasonPlanId, fieldId) => () =>
  commonPost({
    url: `${urlsWrapper('seasonplans')}/${seasonPlanId}/fields/${fieldId}`,
  });

export const deleteSeasonPlansFieldsField = (seasonPlanId, fieldId) => () =>
  commonDelete({
    url: `${urlsWrapper('seasonplans')}/${seasonPlanId}/fields/${fieldId}`,
  });

export const getSeasonPlansWorkRecord = (
  seasonPlanId,
  seasonPlanWorkrecordId
) => async () => {
  const response = await commonGet({
    url: `${urlsWrapper(
      'seasonplans'
    )}/${seasonPlanId}/workrecords/${seasonPlanWorkrecordId}`,
  });
  return response.data;
};

export const getSeasonPlansWorkRecords = (seasonPlanId) => async () => {
  const response = await commonGet({
    url: `${urlsWrapper('seasonplans')}/${seasonPlanId}/workrecords`,
  });
  return response.data;
};

const shouldSeasonPlansWorkRecords = () => true; // todo

export const fetchSeasonPlansWorkRecordsIfNeeded = (seasonPlanId) => (
  dispatch,
  getState
) =>
  shouldSeasonPlansWorkRecords(getState(), seasonPlanId)
    ? dispatch(getSeasonPlansWorkRecords(seasonPlanId))
    : Promise.resolve();

export const getBayerTemplates = async () => {
  const response = await commonGet({
    url: `${urlsWrapper('seasonplansTemplates')}`,
  });
  return response.data;
};

export const addBayerTemplates = (id) =>
  commonPost({
    url: `${urlsWrapper('seasonplansTemplates')}/${id}`,
  });

export default combineReducers({
  seasonPlans: createCommonListReducer(seasonPlans),
  seasonPlansFields: createCommonListReducer(seasonPlansFields),
  seasonPlansWorkrecord: createCommonItemReducer(seasonPlansWorkrecord),
  seasonPlansWorkrecords: createCommonListReducer(seasonPlansWorkrecords),
});

export const getSeasonPlansWorkrecordsSelector = createSelector(
  [
    (state) => state.seasonPlans.seasonPlansWorkrecords.list,
    (state) => state.seasonPlans.seasonPlansWorkrecords.isPending,
  ],
  (list, isPending) => ({
    list,
    isPending,
  })
);

export const getSeasonPlansSelector = createSelector(
  [
    (state) => state.seasonPlans.seasonPlans.list,
    (state) => state.seasonPlans.seasonPlans.isPending,
  ],
  (list, isPending) => ({
    list,
    isPending,
  })
);
