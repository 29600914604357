import { getCustomerId, getAdvisorId, getAdvisorCustomerId } from 'utils';
import {
  env_domain,
  sat_domain,
  geoprocessor_domain,
  soilreceiver_domain,
  env_type,
  rosreestr_domain,
} from 'env';

export const baseUrl = env_domain;
export const satUrl = sat_domain;
export const soilreceiverUrl = soilreceiver_domain;
export const geoprocessorUrl = geoprocessor_domain;
export const rosreestrDomain = rosreestr_domain;

export const baierCustomerId = env_type === 'production' ? 32 : 245;
export const baierAdvisorId = env_type === 'production' ? 3 : 1;

export const test = () => '';

export const urls = (
  customerId = getCustomerId(),
  advisorId = getAdvisorId()
) =>
  // urls object is caching after a module import so there is no way to change customerId later on,
  // so we need to wrap it as a function to get appropriate customerId value on every urls call

  ({
    // System-wide
    login: `${baseUrl}/v1/auth/token`,
    bayertoken: `${baseUrl}/v1/auth/bayertoken`,
    refreshToken: `${baseUrl}/v1/auth/refresh`,
    mapboxToken: `${baseUrl}/v1/mapbox/token`,
    customers: `${baseUrl}/v1/customers`,
    auditEvents: `${baseUrl}/v1/audit/events`,
    customerWorkrecordStats: `${baseUrl}/v1/customers/${customerId}/analytics/workrecords/stats`,
    customersScoutPlans: `${baseUrl}/v1/customers/${customerId}/scoutplans`,
    advisors: `${baseUrl}/v1/advisors`,
    advisorsAnalyticsCustomerstats: `${baseUrl}/v1/advisors/${advisorId}/analytics/customerstats`,
    filledTstrials: `${baseUrl}/v1/customers/${customerId}/analytics/filled_tstrials`,
    filledTstrialsResults: `${baseUrl}/v1/customers/${customerId}/analytics/tstrials_results`,
    filledTstrialsProducts: `${baseUrl}/v1/customers/${customerId}/analytics/tstrials_results/products`,
    advisorsAnalyticsCrops: `${baseUrl}/v1/advisors/${advisorId}/analytics/crops`,
    advisorsScoutsStatistic: `${baseUrl}/v1/advisors/${advisorId}/analytics/scouts_statistic`,
    advisorsVisits: `${baseUrl}/v1/advisors/${advisorId}/analytics/visits`,
    advisorsReport: `${baseUrl}/v1/advisors/${advisorId}/report`,
    notifications: `${baseUrl}/v1/notifications`,
    notificationsReadAll: `${baseUrl}/v1/notifications/read`,
    geoStats: `${baseUrl}/v1/customers/${customerId}/geo/stats`,
    trackers: `${baseUrl}/v1/customers/${customerId}/trackers`,
    trackersGeoprocesses: `${geoprocessorUrl}/v1/customers/${customerId}/trackers`,
    trackersTracks: `${geoprocessorUrl}/v1/customers/${customerId}/trackers/tracks`,
    cadastralParcels: `${baseUrl}/v1/customers/${customerId}/cadastral_parcels`,
    crops: `${baseUrl}/v1/dicts/crops`,
    visitPurposes: `${baseUrl}/v1/dicts/visit_purposes`,
    cropDevStages: `${baseUrl}/v1/dicts/cropdevstages`,
    groupVisitPurposes: `${baseUrl}/v1/dicts/group_visit_purposes`,
    workTypes: `${baseUrl}/v1/dicts/worktypes`,
    pests: `${baseUrl}/v1/dicts/pests`,
    weeds: `${baseUrl}/v1/dicts/weeds`,
    diseases: `${baseUrl}/v1/dicts/diseases`,
    nutritionproblems: `${baseUrl}/v1/dicts/nutritionproblems`,
    weatherproblems: `${baseUrl}/v1/dicts/weatherproblems`,
    workproblems: `${baseUrl}/v1/dicts/workproblems`,
    soilTypes: `${baseUrl}/v1/dicts/soiltypes`,
    tillageTypes: `${baseUrl}/v1/dicts/tillagetypes`,
    seeds: `${baseUrl}/v1/dicts/seeds`,
    seedVendors: `${baseUrl}/v1/dicts/seedvendors`,
    pesticides: `${baseUrl}/v1/dicts/pesticides`,
    pesticideVendors: `${baseUrl}/v1/dicts/pesticidevendors`,
    fertilizers: `${baseUrl}/v1/dicts/fertilizers`,
    fertilizerVendors: `${baseUrl}/v1/dicts/fertilizervendors`,
    regions: `${baseUrl}/v1/regions`,
    bayerRegions: `${baseUrl}/v1/bayer_regions`,
    districts: `${baseUrl}/v1/regions/districts`,
    scoutproxyimages: `${baseUrl}/v1/customers/${customerId}/scoutproxyimages`,
    // Other common
    userInfo: customerId
      ? `${baseUrl}/v1/customers/${customerId}`
      : `${baseUrl}/v1/advisors/${advisorId}`,
    users: customerId
      ? `${baseUrl}/v1/customers/${customerId}/users`
      : `${baseUrl}/v1/advisors/${advisorId}/users`,
    usersCommon: `${baseUrl}/v1/users`,
    // for auth.js we need to query real user no matter the user has been selected from advisor
    authUsers: getCustomerId()
      ? `${baseUrl}/v1/customers/${getCustomerId()}/users`
      : `${baseUrl}/v1/advisors/${advisorId}/users`,
    advisorUsers: `${baseUrl}/v1/customers/${customerId}/advisorusers`,
    profile: `${baseUrl}/v1/customers/${customerId}/profile`,
    seasonplansTemplates: `${baseUrl}/v1/customers/${customerId}/seasonplans/templates`,
    fields: `${baseUrl}/v1/customers/${customerId}/fields`,
    equipments: `${baseUrl}/v1/customers/${customerId}/equipment`,
    equipmentTypes: `${baseUrl}/v1/dicts/equipment_types`,
    geoTrackers: `${geoprocessorUrl}/v1/customers/${customerId}/trackers/current`,
    geoTrackersMachines: `${geoprocessorUrl}/v1/extents/tracker`,
    geoprocessorFields: `${geoprocessorUrl}/v1/fields`,
    geoprocessorExtents: `${geoprocessorUrl}/v1/extents`,
    markers: `${baseUrl}/v1/customers/${customerId}/markers`,
    bounds: `${baseUrl}/v1/customers/${customerId}/fields/bounds`,
    divisions: `${baseUrl}/v1/customers/${customerId}/divisions`,
    divisionGroups: `${baseUrl}/v1/customers/${customerId}/divisiongroups`,
    scouts: `${baseUrl}/v1/customers/${customerId}/scouts`,
    scoutsReport: `${baseUrl}/v1/customers/${customerId}/report`,
    scoutsUpload: `${baseUrl}/v1/customers/${customerId}/scouts/upload`,
    scoutsAll: `${baseUrl}/v1/customers/${customerId}/scouts/all`,
    topics: `${baseUrl}/v1/customers/${customerId}/topics`,
    analyticsScouts: `${baseUrl}/v1/customers/${customerId}/analytics/scouts`,
    analyticsCrops: `${baseUrl}/v1/customers/${customerId}/analytics/crops`,
    weatherSources: `${baseUrl}/v1/customers/${customerId}/weather/sources`,
    weatherGeojson: `${baseUrl}/v1/customers/${customerId}/weather/sources/geojson`,
    documents: `${baseUrl}/v1/customers/${customerId}/documents`,
    seasonplans: `${baseUrl}/v1/customers/${customerId}/seasonplans`,
    plots: `${baseUrl}/v1/customers/${customerId}/fields/plots`,
    plotsGeojson: `${baseUrl}/v1/customers/${customerId}/fields/plots/geojson`,
    groupsByType: `${baseUrl}/v1/customers/${customerId}/workrecords/groups_by_type`,
    workrecords: `${baseUrl}/v1/customers/${customerId}/workrecords`,
    machines: `${baseUrl}/v1/customers/${customerId}/machines`,
    recommendations: `${baseUrl}/v1/customers/${customerId}/recommendations`,
    satFields: `${satUrl}/v1/fields`,
    satDivisions: `${satUrl}/v1/divisions`,
    satFieldRelief: `${satUrl}/v1/field_slopes`,
    satDivisionRelief: `${satUrl}/v1/division_slopes`,
    satImages: `${satUrl}/v1/images`,
    soilreceiverVdsHistory: `${soilreceiverUrl}/v1/fields/`,
    geometryValidation: `${baseUrl}/v1/validate/geometry`,
    cropParamsSchema: `${baseUrl}/v1/cropparamsschema/default`,
    bayerTsCustomers: `${baseUrl}/v1/bayer_ts_customers`,
    bayerTsCustomersVisitsStats: `${baseUrl}/v1/bayer_ts_customers/analytics/visitstats`,
    satFieldsManagementZones: `${satUrl}/v1/fields`,
    trackersTypes: `${baseUrl}/v1/customers/${customerId}/geo/service`,
    twoBreedsCompare: `${baseUrl}/v1/customers/${customerId}/analytics/tstrials_breeds/comparison`,
    competitorsCompare: `${baseUrl}/v1/customers/${customerId}/analytics/tstrials_breeds/competitors`,
    availableadvisorusers: `${baseUrl}/v1/customers/${customerId}/availableadvisorusers`,
    soilProperties: `${baseUrl}/v1/dicts/soil_properties`,
    soilList: `${baseUrl}/v1/customers/${customerId}/fields`,
    mechanizers: `${baseUrl}/v1/customers/${customerId}/machine_operators`,
    productsList: `${baseUrl}/v1/bayer_ts_customers`,
    settings: `${baseUrl}/v1/customers/${customerId}/settings`,
    cropcolorsSettings: `${baseUrl}/v1/customers/${customerId}/settings/cropcolors`,
    getSeedsListAnalitics: `${baseUrl}/v1/customers/${customerId}/analytics/tstrials_breeds/products`,
    rosreestrFeatures: `${rosreestrDomain}/api/aeggis/v3/36048`,
    rosreestrArcgis: `${rosreestrDomain}/arcgis`,
    ndviDynamics: `${satUrl}/v1/customers/${customerId}/analytics/ndvi_dynamics`,
  });

export const urlsWrapper = (item) => {
  const customerId = getAdvisorCustomerId() || getCustomerId();
  return urls(customerId)[item];
};
