import { urlsWrapper } from 'config';
import { createAction } from 'redux-actions';
import { combineReducers } from 'redux';
import { getAdvisorCustomerId, getCustomerId, serialize } from 'utils';
import { getCurrentCustomer } from 'modules/auth';
import { env_externalservices } from 'env';
import {
  saveData,
  createCommonListConstants,
  createCommonItemConstants,
  createCommonItemReducer,
  createCommonListReducer,
} from './utils';
import { commonPost, commonPut, commonDelete, commonGet } from './api';

const constantsWorkrecords = createCommonListConstants('WORK_RECORDS');
const constantsTracksGeojson = createCommonItemConstants('TRACKS_GEOJSON');
const constantsTracks = createCommonListConstants('TRACKS');

export default combineReducers({
  workrecords: createCommonListReducer(constantsWorkrecords),
  tracksGeojson: createCommonItemReducer(constantsTracksGeojson),
  tracks: createCommonItemReducer(constantsTracks),
});

// actions
export const addWorkRecord = (
  fieldOrSeasonPlanId,
  data,
  isSeasonPlan = false
) => () => {
  const baseUrl = isSeasonPlan
    ? urlsWrapper('seasonplans')
    : urlsWrapper('fields');
  return commonPost({
    url: `${baseUrl}/${fieldOrSeasonPlanId}/workrecords`,
    data,
    name: 'работу',
  }).catch((e) => {
    if (
      e?.errors?.err?.message ===
      'Workrecord with same sow/harvest type already exists'
    ) {
      const text =
        'К одному участку может относиться только одна запись по севу/уборке. В том числе,вы не можете добавить запись по севу/уборке для всего поля (фона), если такие записи уже добавлены на участках. Если вы не знаете, как корректно внести информацию,обратитесь в поддержку, мы с удовольствием вам поможем.';
      e.errors._error = text;
      throw e;
    } else {
      throw e;
    }
  });
};

export const updateWorkRecord = (
  workRecordId,
  fieldOrSeasonPlanId,
  data,
  isSeasonPlan = false
) => () => {
  const baseUrl = isSeasonPlan
    ? urlsWrapper('seasonplans')
    : urlsWrapper('fields');
  return commonPut({
    url: `${baseUrl}/${fieldOrSeasonPlanId}/workrecords/${workRecordId}`,
    data,
    name: 'работы',
  });
};

export function getWorkRecords(fieldOrSeasonPlanId, isSeasonPlan = false) {
  const baseUrl = isSeasonPlan
    ? urlsWrapper('seasonplans')
    : urlsWrapper('fields');
  return saveData(
    `${baseUrl}/${fieldOrSeasonPlanId}/workrecords?embed=machinery`,
    constantsWorkrecords.SET_LIST,
    constantsWorkrecords.SET_IS_PENDING
  );
}

const shouldFetchWorkRecords = (state, fieldId) =>
  !state.workRecords.workrecords.list.length ||
  !state.workRecords.workrecords.list.find(
    ({ field_id }) => field_id === fieldId
  );

export const fetchWorkRecordsIfNeeded = (fieldOrSeasonPlanId, isSeasonPlan) => (
  dispatch,
  getState
) =>
  shouldFetchWorkRecords(getState(), fieldOrSeasonPlanId)
    ? dispatch(getWorkRecords(fieldOrSeasonPlanId, isSeasonPlan))
    : Promise.resolve();

export const deleteWorkRecord = (
  workRecordId,
  fieldOrSeasonPlanId,
  isSeasonPlan = false
) => () => {
  const baseUrl = isSeasonPlan
    ? urlsWrapper('seasonplans')
    : urlsWrapper('fields');
  return commonDelete({
    url: `${baseUrl}/${fieldOrSeasonPlanId}/workrecords/${workRecordId}`,
  });
};

export const getTracks = (fieldId, workrecordId) =>
  commonGet({
    url: `${urlsWrapper('geoprocessorFields')}/${fieldId}/extents`,
    params: {
      workrecord_id: workrecordId,
      machine_type:
        'UNKNOWN,HARVESTER,TRACTOR,SPRAYER,RIDING_MOWER,LOADER,GRADER,CASTRATOR',
    },
  });

export const getTracksGeojson = (extentId) =>
  commonGet({
    url: `${urlsWrapper('geoprocessorExtents')}/${extentId}/geojson`,
  });

export const getTracksByWorkrecords = (props, constantsProp = null) => async (
  dispatch
) => {
  const customerId = getAdvisorCustomerId() || getCustomerId();
  const workrecords = props?.season
    ? (props?.workrecords || []).filter(
        ({ season }) => season === props?.season
      )
    : props?.workrecords;
  if (Array.isArray(workrecords) && workrecords.length) {
    dispatch(
      createAction(
        constantsProp
          ? constantsProp.SET_IS_PENDING
          : constantsTracks.SET_IS_PENDING
      )(true)
    );
    const { geo_enabled } =
      (await dispatch(getCurrentCustomer(customerId))) || {};
    const result =
      env_externalservices === 'true' && geo_enabled
        ? await Promise.all(
            workrecords.map(async (workrecord) => {
              return {
                ...workrecord,
                tracks:
                  ((await getTracks(workrecord.field_id, workrecord.id)) || {})
                    ?.data?.data || [],
              };
            })
          )
            .then((d) => {
              dispatch(
                createAction(
                  constantsProp
                    ? constantsProp.SET_LIST
                    : constantsWorkrecords.SET_LIST
                )(d)
              );
              return d;
            })
            .catch(() => workrecords)
        : workrecords;
    dispatch(
      createAction(
        constantsProp
          ? constantsProp.SET_IS_PENDING
          : constantsTracks.SET_IS_PENDING
      )(false)
    );
    return result;
  }
  return Promise.resolve([]);
};

export const getWorkRecordsWithTracks = (fieldId, season) => async (
  dispatch
) => {
  const workrecords = await dispatch(
    saveData(
      `${urlsWrapper('fields')}/${fieldId}/workrecords?${serialize({
        embed: 'machinery',
        ...(season ? { season } : {}),
      })}`,
      constantsWorkrecords.SET_LIST,
      constantsWorkrecords.SET_IS_PENDING
    )
  );
  return dispatch(getTracksByWorkrecords({ workrecords, season }));
};

export function getWorkRecord(
  fieldOrSeasonPlanId,
  workrecordId,
  isSeasonPlan = false
) {
  const baseUrl = isSeasonPlan
    ? urlsWrapper('seasonplans')
    : urlsWrapper('fields');
  return commonGet({
    url: `${baseUrl}/${fieldOrSeasonPlanId}/workrecords/${workrecordId}`,
  });
}

export const getWorkRecordsList = (params) =>
  commonGet({
    url: `${urlsWrapper('workrecords')}`,
    params,
  });
